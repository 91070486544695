<template>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <div class="col-xxl-9">
          <div class="card mt-xxl-n5">
            <div class="card-body p-4">
              <div class="tab-content">
                <div class="tab-pane active" id="personalDetails" role="tabpanel">
                  <form >
                    <div class="row">
                      <div class="col-lg-4">
                        <label for="fac" class="form-label">Filière</label>
                        <Multiselect
                          id="fac"
                          class="form-control"
                          v-model="data.faculty"
                          :class="{ 'is-invalid': v$.data.faculty.$error && data.faculty || v$.data.faculty.$error && submited,}"
                          :close-on-select="true"
                          :searchable="false"
                          :show-labels="false"
                          :options="facultiesOptions"
                        />
                          <div
                          v-for="(item, index) in v$.data.faculty.$errors"
                          :key="index"
                          class="invalid-feedback"
                          >
                          <span v-if="item.$message && data.faculty || v$.data.faculty.$error && submited">{{ item.$message }}</span>
                        </div>
                      </div>

                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label for="mat" class="form-label">Matière</label>
                          <Multiselect
                          id="mat"
                          class="form-control"
                          v-model="data.Matter"
                          @input="onChangeMatter"
                          :class="{ 'is-invalid': v$.data.Matter.$error && data.Matter || v$.data.Matter.$error && submited,}"
                          :close-on-select="true"
                          :searchable="false"
                          :show-labels="false"
                          :options="mattersOptions"
                        />
                        <div
                        v-for="(item, index) in v$.data.Matter.$errors"
                        :key="index"
                        class="invalid-feedback"
                      >
                        <span v-if="item.$message && data.Matter || v$.data.Matter.$error && submited">{{ item.$message }}</span>
                      </div>
                        </div>
                      </div>

                      <div class="col-xxl-1 col-sm-4">
                        <div class="m-h-28">
                          <button 
                            type="button"
                            class="btn btn-primary w-100 "
                            :disabled="data.faculty.length === 0 || data.Matter.length === 0"
                            @click="filterExams"
                          >
                            <i class="ri-equalizer-fill me-1 align-bottom"></i>
                            Filters
                          </button>
                        </div>
                      </div>
                      <div class="col-xxl-9">
                          <div class="card mt-xxl-n5">
                            <table class="table table-striped">
                              <thead>
                                  <tr>
                                      <th scope="col">Examen</th>

                                      <th scope="col">semestre</th>

                                      <th scope="col">coeffiscient</th>

                                      <th scope="col">dateCompo</th>

                                      <th scope="col">Action</th>

                                  </tr>

                              </thead>

                              <tbody>

                                  <tr v-for="filExam in filteredExams" :key="filExam.id">

                                      <th scope="row">{{ filExam.type_exam }}</th>

                                      <td>{{ filExam.semestre }}</td>

                                      <td>{{ filExam.coeffiscient }}</td>

                                      <td>{{ filExam.dateCompo }}</td>
                                      <td>
                                          <div class="hstack gap-3 flex-wrap">
                                              <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#exampleModalgrid" @click="getExamStudents(filExam)" class="link-success fs-15"><i class="ri-edit-2-line"></i></a>
                                          </div>
                                      </td>
                                  </tr>
                              </tbody>
                            </table>
                          </div>
                      </div>
                    </div>
                    <!--end row-->
                  </form>
                </div>
              </div>
            </div>
          </div>
          <!-- Grids in modals -->
          <div class="modal fade" id="exampleModalgrid" tabindex="-1" aria-labelledby="exampleModalgridLabel" aria-modal="true">
              <div class="modal-dialog">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalgridLabel">Notes</h5>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                          <form action="javascript:void(0);">
                            <div class="card mt-xxl-n5">
                            <table class="table table-striped">
                              <thead>
                                  <tr>
                                      <th scope="col">Id</th>

                                      <th scope="col">Etudiant</th>

                                      <th scope="col">Note sur 20</th>

                                  </tr>
                              </thead>

                              <tbody>

                                  <tr v-for="student in filteredstudents" :key="student.id">

                                      <th scope="row">{{ student.id }}</th>

                                      <td>{{ student.name }}</td>

                                      <td> 
                                         <div class="col-xxl-3">
                                           <input class="form-control-sm" type="number" v-model="student.inputValue" required> 
                                         </div>
                                      </td>

                                  </tr>
                                  
                              </tbody>
                            </table>
                          </div>
                          <div class="col-lg-12">
                            <div class="hstack gap-2 justify-content-end">
                              <button @click="submit" class="btn btn-primary" :disabled="spanView">
                                <span v-if="spanView" class="spinner-border spinner-border-sm"></span>
                                Valider
                              </button>
                            </div>
                          </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </Layout>
</template>

<style scoped>
.m-h-28{
  margin-top: 28px;
}
</style>

<script>
  import { required,helpers } from "@vuelidate/validators";
  import useVuelidate from "@vuelidate/core";
  import Layout from "../../layouts/main.vue";
  import PageHeader from "@/components/page-header";
  import Multiselect from "@vueform/multiselect";
  import Swal from "sweetalert2";
  import "@vueform/multiselect/themes/default.css";
  
  
  export default {
    setup() {
      return { v$: useVuelidate() };
     },
  
    data() {
      return {
        data: {
          faculty:'',
          Matter:'',
          spanView: false,

        },
        faculties: {},
        facultiesOptions: [], 
        mattersOptions: [], 
        matters: {},
        exams: [],
        filteredExams: [],
        currentExamId: null,
        students: [],
        filteredstudents: [],
        title: "Gestion Notes",
        items: [
          {
            text: "Notes",
            href: "/",
          },
          {
            text: "Notes",
            active: true,
          },
        ],
      };
    },
    validations: {
      data: {
        faculty: {
          required: helpers.withMessage("Veuillez selectionnez une filière", required),
        },
        Matter: {
          required: helpers.withMessage("Veuillez selectionnez un année scolaire", required),
        },
      },
    },
    components: {
      Layout,
      PageHeader,
      Multiselect,
    },
    mounted() {
      this.getAllFaculties();
      this.getAllMatters();
      this.getAllExams();
    },
    methods: {
      getAllFaculties(){
        const self = this;
        this.$store.dispatch('getRequest',{route:'/api/getAllFaculties', data: this.data})
        .then(
          function(response){
            self.faculties = response.data.filieres;

            self.faculties.forEach(element => {

              self.facultiesOptions.push({value: element.id , label: element.code });

            });
          },
          function(error){
            if (error !== null) {
              console.log('error');
            }
          }
        )
      },

      getAllMatters(){
        const self = this;
        this.$store.dispatch('getRequest',{route:'/api/getAllMatters', data: this.data})
        .then(
          function(response){
            self.matters = response.data.matieres;

            self.mattersOptions = [],

            self.matters.forEach(element => {
              
              self.mattersOptions.push({value: element.id , label: element.title });

            });
            
          },
          function(error){
            if (error !== null) {
              console.log('error');
            }
          }
        )
      },

      getAllExams(){
        const self = this;
        this.$store.dispatch('getRequest',{route:'/api/getExams', data: this.data})
        .then(
          function(response){
            self.exams = response.data.exams;
          },
          function(error){
            if (error !== null) {
              console.log('error');
            }
          }
        )
       },

       getExamStudents(exam){
        this.currentExamId = exam.id;
        const self = this;
        this.$store.dispatch('getRequest',{route:'/api/getExamStudents', data: exam})
        .then(
          function(response){
            self.students = response.data.students;
            self.filteredstudents = self.students.filter(function(student){
              if(student.faculty_id == exam.faculty_id && student.promotion == localStorage.getItem('selectedYear')){
                return true
              }
            })
          },
          function(error){
            if (error !== null) {
              console.log('error');
            }
          }
        )
       },

       submit() {
         this.spanView = true;
        let studentNote = this.filteredstudents.map(filStudentNote => {
            return {
              student: filStudentNote.id,
              note: filStudentNote.inputValue,
              exam: this.currentExamId,
            }
          });
          
          const donne = new FormData();

          donne.append('studentsNote', JSON.stringify(studentNote));
          const self = this;
          this.$store.dispatch('postRequest',{route:'/api/uploadNotes',  data: donne})
          .then(
            function(response){
              self.spanView = false;
              self.filteredExams = [];
              Swal.fire(response.data.message, "Les notes ont été enregistrées!", "success");
            },
            function(error){
              self.spanView = false;
            if (error !== null) {
              Swal.fire({
              title: "Oops...",
              text: "Il y a eu une erreur!",
              icon: "error",
              confirmButtonClass: "btn btn-primary w-xs mt-2",
              buttonsStyling: false,
              showCloseButton: true,
            });              
            }
            }
          )
       },
       filterExams(){
        const self = this;
        this.filteredExams = this.exams.filter(exam => exam.faculty_id == self.data.faculty && exam.matter_id == self.data.Matter && exam.AnneeScolaire == localStorage.getItem('selectedYear'));
       }
    },
  };
  </script>
  
  